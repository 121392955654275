import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Em, Span, Box, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 50px 0",
	"background": "--color-darkL1",
	"quarkly-title": "Footer-17"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"lg-width": "100%",
			"flex-direction": "row",
			"lg-flex-direction": "column",
			"justify-content": "space-between",
			"width": "100%",
			"padding": "0 0px 0 0px",
			"sm-flex-direction": "column",
			"sm-padding": "0 0px 0 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"lg-margin": "0px 0px 0px 0px",
			"width": "50%",
			"display": "flex",
			"lg-width": "70%",
			"sm-width": "100%",
			"flex-direction": "column",
			"padding": "0px 50px 0px 0px",
			"lg-padding": "0px 0 0px 0px",
			"sm-margin": "0px 0px 35px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "--headline3",
			"color": "--light",
			"letter-spacing": "1px",
			"children": "MetroVille"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0 0px 35px 0px",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"color": "--light",
			"sm-text-align": "left",
			"sm-margin": "0 0px 0 0px",
			"lg-max-width": "640px",
			"children": <Span
				font="--headline3"
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					font="--lead"
				>
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Відкрийте мрію передмістя
					</Em>
				</Span>
			</Span>
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "54px",
			"lg-align-items": "start",
			"md-grid-template-columns": "repeat(3, 1fr)",
			"md-grid-gap": "36px 34px",
			"sm-grid-template-columns": "1fr",
			"sm-grid-gap": "16px 0",
			"justify-content": "flex-end",
			"sm-flex-direction": "column",
			"display": "flex",
			"lg-justify-content": "flex-start",
			"lg-display": "flex"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"margin": "0px 0px 0px 0",
			"lg-align-items": "flex-start",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-flex-direction": "column",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "column",
			"align-content": "start",
			"grid-gap": "10px 0"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "/index",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"letter-spacing": "1px",
			"children": "Головна"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"display": "flex",
			"href": "/services",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"letter-spacing": "1px",
			"children": "Послуги"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#ffffff",
			"href": "/faq",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"display": "flex",
			"letter-spacing": "1px",
			"children": <>
				FAQ{" "}
			</>
		}
	}
};

const Header = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" justify-content="flex-start" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<Box {...override("box2")}>
				<Box {...override("box3")}>
					<Link {...override("link")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Header, { ...Section,
	defaultProps,
	overrides
});
export default Header;